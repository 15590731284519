import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import DownloadApp from '../components/DownloadApp';
import { Link } from "gatsby"

export default () => (
  <Layout>
    <SEO title="Julia's testimonial" />

    <div>
      <div className="section bg-gray-4">
        <div className="container">
          <div className="row row-split-content full-width">
            <div className="content-width-medium">
              <h1 className="h3">"How to Be Positive’ helps you to think, feel, and be more positive in any situation."</h1>
              <div className="div-block"><img width={74} src="/fonts/5ddcc802eb72505d98d07190_20-People%20Avatar-Woman.svg" alt="" className="case-study-logo" />
                <div>
                  <div className="handwriting">Julia</div>
                  <div>Wise Guide User</div>
                </div>
              </div>
            </div>
            <div className="content-width-large"><img src="/images/5ddcc5cc965f2458278f28d9_BMWz4P_t20_AowOzP.jpg" alt="" sizes="(max-width: 479px) 95vw, (max-width: 767px) 90vw, (max-width: 991px) 612px, 46vw" srcSet="/images/5ddcc5cc965f2458278f28d9_BMWz4P_t20_AowOzP-p-800.jpeg 800w, /images/5ddcc5cc965f2458278f28d9_BMWz4P_t20_AowOzP.jpg 892w" /></div>
          </div>
        </div>
      </div>
      <div className="section bg-gray-4">
        <div className="container">
          <div className="w-layout-grid sidebar-layout case-study-layout">
            <div className="sidebar">
              <div className="card shadow-small">
                <ul className="w-list-unstyled">
                  <li className="card-body border-bottom">
                    <h6 className="h6-small no-bottom-space">Name</h6>
                    <div>Julia</div>
                  </li>
                  <li className="card-body border-bottom">
                    <h6 className="h6-small no-bottom-space">Programs</h6>
                    <div>The Love Tapes, How To Be Positive</div>
                  </li>
                  <li className="card-body border-bottom">
                    <h6 className="h6-small no-bottom-space">Results</h6>
                    <div>Decades of inspiration and hope.</div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="content-width-large">
                <div className="article w-richtext">
                  <p>Julia lives in Kansas City, Missouri and works as a registered nurse. It’s been decades since she picked up her first copy of The Love Tapes that has filled her life with “benefits and graces.”</p>
                  <p>“I don’t remember how I first came across a little flyer about The Love Tapes back in the late 70s or early 80s,” begins Julia. “But I ordered ‘How To Be Positive’ and still have the original cassette in working condition, though not from of lack of use!”</p>
                  <p>‘How to Be Positive’ helps you to think, feel, and be more positive in any situation. Julia says she’s recommended the tapes to her patients, family and friends. “I stopped counting how many of these jewels I own when I accumulated more than forty,” she shares.</p>
                  <p>“I realized I owe Effective Learning more than just my gratitude. Bob Griswold was at my side during what was nothing less than my dark night of the soul. For many years, the Love Tapes were my lifeline. Now, the tapes continue to be an absolute joy and my number one referral source for all of my clients.”</p>
                  <p>Julia is very appreciative. “My deep thanks for decades of inspiration and hope.”</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section bg-gray-4">
        <div className="container">
          <div className="row row-justify-center">
            <div className="section-title-wide">
              <h3 className="h2 no-bottom-space">More success stories</h3>
            </div>
          </div>
          <div className="w-dyn-list">
            <div className="case-study-cards-grid w-dyn-items">
              <div className="w-dyn-item">
                <div className="card case-study-card">
                  <div className="card-body case-study-card-body"><img src="/images/5ddc49d9315b303c82a6af7b_15-People%20Avatar-Man.png" alt="" className="case-study-logo" />
                    <p className="case-study-card-excerpt">The programs have enabled me to increase my focus on positive outcomes. The process of listening for 30 days seems to be an effective way to drive home points about positive approaches to problems</p><Link to="/dorsey/">Read their story</Link>
                  </div>
                </div>
              </div>
              <div className="w-dyn-item">
                <div className="card case-study-card">
                  <div className="card-body case-study-card-body"><img src="/images/5ddc498444a1cc7c9762354a_26-People%20Avatar-Woman.png" alt="" className="case-study-logo" />
                    <p className="case-study-card-excerpt">Jenny was able to gain more confidence and lose weight using the Wise Guide app</p><Link to="/jenny/">Read their story</Link>
                  </div>
                </div>
              </div>
              <div className="w-dyn-item">
                <div className="card case-study-card">
                  <div className="card-body case-study-card-body"><img src="/images/5ddc4bc87dc9835069f5ccfc_18-People%20Avatar-Woman.png" alt="" className="case-study-logo" />
                    <p className="case-study-card-excerpt">The program explained how to eject negatives and to choose to be confident. I started by smiling at other people and of being loving towards them. </p><Link to="/annie-marie/">Read their story</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <DownloadApp />
  </Layout>
);
